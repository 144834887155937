<template>
  <div>
    <div v-if="historyData" class="history" @click="goDetail">
      <span>查看上次浏览记录</span>
      <img src="../../../assets/images/interviewQuestionBank/close.png" alt="" @click="historyData=null">
    </div>
    <div class="section">
      <!--课程分类-->
      <div v-loading="classificationLoading" class="course-classification">
        <div
          v-for="(item, index) in course_classification"
          :key="index"
          class="course-classification-item"
          :class="{'course-classification-item-select':courseClassificationSelect===item.value}"
          @click="selectCourseClassification(item.value)"
        >
          {{ item.label }}
        </div>
<!--        <div class="course-classification-add" @click="openAdd">+</div>-->
      </div>
      <div v-loading="questionListLoading" class="question-box">
        <!--搜索条件-->
        <div class="question-search">
          <div>
            <el-select v-model="knowledgeLabel" style="width: 150px" multiple clearable collapse-tags placeholder="知识点" @change="onRefresh">
              <el-option
                v-for="item in knowledgeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-select v-model="enterpriseLabel" style="width: 150px;margin-left: 10px" multiple clearable collapse-tags placeholder="企业" @change="onRefresh">
              <el-option
                v-for="item in enterpriseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-select v-model="difficultyFactor" style="width: 150px;margin-left: 10px" clearable placeholder="难度" @change="onRefresh">
              <el-option
                  v-for="item in degree_difficulty"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
          <div>
            <el-input
              v-model="questionKeyWord"
              placeholder="请输入题目名称"
              :suffix-icon="questionKeyWord?'none':'el-icon-search'"
              maxlength="12"
              clearable
              @change="onRefresh"
            />
          </div>
        </div>
        <!--列表-->
        <div v-if="testQuestionList.length" class="question-list">
          <div v-for="item in testQuestionList" :key="item.id" class="question-list-item" @click="handleDetail(item)">
            <el-card shadow="hover">
              <div class="question-header">
                <div class="question-title">
                  {{ item.topicContentText }}
                </div>
                <dict-tag :options="degree_difficulty" :value="item.difficultyFactor" />
              </div>
              <div class="question-label">
                <div v-for="(item1, index) in item.totalLabel" :key="index" class="question-label-item">
                  {{ item1 }}
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <el-empty v-else description="暂无内容" :image="require('@/assets/images/empty.png')" />
        <el-backtop target=".app" />
      </div>
    </div>
    <el-dialog
      class="dialog-box"
      :close-on-click-modal="false"
      title="添加题库"
      :visible.sync="dialogVisible"
      width="20%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="添加题库" prop="wordType">
          <el-select v-model="courseClassificationAdd" class="menu-search-select" multiple clearable placeholder="请选择">
            <el-option
              v-for="item in course_classification"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round :loading="submitLoading" @click="submitAdd">保 存</el-button>
        <el-button type="info" round @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getHistoryData, labelByStuHome } from '@/api/interviewQuestionBank/interviewQuestion'

import { mapState } from 'vuex'
import { addMyClassification } from '@/api/interviewQuestionBank/interviewQuestion'
// import { getMyClassification } from '@/api/interviewQuestionBank/interviewQuestion'
import { getInterviewQuestion } from '@/api/interviewQuestionBank/interviewQuestion'
import DictTag from "@/components/DictTag/index.vue";

export default {
  name: 'InterviewQuestion',
  components: {DictTag},
  props: {
    testClassification: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      // 知识点和企业标签
      knowledgeList: [],
      enterpriseList: [],
      // 课程分类 和 选中的分类
      classificationLoading: false,
      courseClassification: [],
      courseClassificationSelect: 0,
      // 添加时
      courseClassificationAdd: [],
      // 是否有历史记录
      historyData: null,
      // 试题列表
      questionListLoading: false,
      testQuestionList: [],
      // 参数
      pageNum: 1,
      knowledgeLabel: [],
      enterpriseLabel: [],
      difficultyFactor: '',
      questionKeyWord: '',
      total: 0
    }
  },
  computed: {
    ...mapState({
      degree_difficulty: state => state.dict.dictTree['degree_difficulty']['arr'],
      course_classification: state => {
        return state.dict.dictTree.course_classification['arr'].filter(item => {
          return item.status === '0'
        })
      },
      course_classification_obj: state => {
        const obj = {}
        state.dict.dictTree.course_classification['arr'].filter(item => item.status === '0').forEach(item => {
          obj[item.value] = item.label
        })
        return obj
      },
      userInfo: state => state.user.userInfo
    })
  },
  watch: {
    testClassification() {
      this.onRefresh()
    }
  },
  async created() {
    await this.getCourseClassification()
    await this.getLabelCollection()
    await this.getHistory()
    await this.onload()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    onRefresh() {
      this.pageNum = 1
      this.onload()
    },
    // 知识点和企业标签
    async getLabelCollection() {
      const obj = {
        testClassification: this.testClassification,
        courseClassification: this.courseClassificationSelect
      }
      labelByStuHome(obj).then(res => {
        if (res.code === 200) {
          this.knowledgeList = res.data?.knowledgeTagList ? [...res.data.knowledgeTagList] : []
          this.enterpriseList = res.data?.enterpriseLabelList ? [...res.data.enterpriseLabelList] : []
        }
      })
    },
    // 课程类型
    async getCourseClassification() {
      this.courseClassificationSelect = this.course_classification[0].value
    //   this.classificationLoading = true
    //   await getMyClassification({ studentId: this.userInfo.id }).then(async res => {
    //     const list = res.rows.filter(item => {
    //       return this.course_classification.some(item1 => item1.value === item.courseClassification)
    //     })
    //     if (list && list.length) {
    //       this.courseClassification = res.rows.filter(item => {
    //         return this.course_classification.some(item1 => item1.value === item.courseClassification)
    //       }).map(item => {
    //         return item.courseClassification
    //       }) || []
    //       this.courseClassificationSelect = this.courseClassification.length ? this.courseClassification[0] : ''
    //       this.classificationLoading = false
    //     } else {
    //       const courseClassification = this.course_classification.slice(0, 5).map(item => {
    //         return item?.value
    //       })
    //       const obj = {
    //         studentId: this.userInfo.id,
    //         courseClassification: courseClassification?.join(',') || 0
    //       }
    //       await addMyClassification(obj).then(res => {
    //         if (res.code === 200) {
    //           this.courseClassification = courseClassification
    //           this.courseClassificationSelect = courseClassification[0]
    //           this.classificationLoading = false
    //         }
    //       })
    //     }
    //   })
    },
    onload() {
      const obj = {
        studentId: this.userInfo.id,
        pageNum: this.pageNum,
        pageSize: 10,
        testClassification: this.testClassification,
        courseClassification: this.courseClassificationSelect,
        enterpriseLabel: this.enterpriseLabel?.join(',') || null,
        knowledgeTag: this.knowledgeLabel?.join(',') || null,
        difficultyFactor: this.difficultyFactor || '',
        topicContentText: this.questionKeyWord
      }
      if (this.pageNum === 1) {
        this.questionListLoading = true
      }
      getInterviewQuestion(obj).then(res => {
        if (res.code === 200) {
          const list = res.rows.map(item => {
            return {
              ...item,
              totalLabel: item?.totalLabel ? item?.totalLabel?.split(',')?.slice(0, 8) : []
            }
          })
          if (this.pageNum === 1) {
            this.testQuestionList = list
          } else {
            this.testQuestionList.push(...list)
          }
          this.total = res.total
          this.questionListLoading = false
        }
      })
    },
    // 获取历史记录
    getHistory() {
      // this.historyData = null
      getHistoryData({ stuId: this.userInfo.id }).then(res => {
        if (res.code === 200) {
          this.historyData = res.data
        }
      })
    },
    // 去历史
    goDetail() {
      if (this.historyData.questionBankDel) {
        this.$message.warning('该题目已被删除')
        this.historyData = null
        return
      }
      const routeData = this.$router.resolve({
        name: 'interviewQuestionDetail',
        query: {
          questionId: this.historyData.questionBankId,
          courseClassification: this.historyData.courseClassification,
          testClassification: this.historyData.testClassification
        }
      })
      this.historyData = null
      window.open(routeData.href, '_blank')
    },
    // 去详情
    handleDetail(item) {
      const routeData = this.$router.resolve({
        name: 'interviewQuestionDetail',
        query: {
          questionId: item?.id,
          courseClassification: item.courseClassification,
          testClassification: item.testClassification
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 点击课程分类
    selectCourseClassification(index) {
      this.courseClassificationSelect = index
      this.getLabelCollection()
      this.onRefresh()
    },
    openAdd() {
      this.dialogVisible = true
      this.courseClassificationAdd = [...this.courseClassification]
    },
    submitAdd() {
      if (this.courseClassificationAdd.length) {
        const obj = {
          studentId: this.userInfo.id,
          courseClassification: this.courseClassificationAdd?.join(',')
        }
        addMyClassification(obj).then(res => {
          if (res.code === 200) {
            this.courseClassification = [...this.courseClassificationAdd]
            this.courseClassificationSelect = this.courseClassificationAdd[0]
            this.dialogVisible = false
            this.onRefresh()
          }
        })
      } else {
        this.$message.warning('选中项不能为空')
      }
    },
    // 滚动操作
    handleScroll(e) {
      const dom = document.querySelector('#app')
      const bottomBox = document.querySelector('.IndexFooter')
      // 文档内容实际高度（包括超出视窗的溢出部分）
      const scrollHeight = Math.max(dom.scrollHeight, dom.scrollHeight)
      // 滚动条滚动距离
      const scrollTop = e.target.scrollTop
      // 窗口可视范围高度
      const clientHeight = dom.innerHeight || Math.min(dom.clientHeight, dom.clientHeight)
      if (clientHeight + scrollTop >= scrollHeight - bottomBox.clientHeight) {
        if (this.pageNum * 10 >= this.total) return
        this.pageNum += 1
        this.onload()
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/styles/diyDialog";

.history{
  height: 32px;
  background: rgba(40,113,234,0.1);
  border: 1px solid #2871EA;
  line-height: 32px;
  color: #2871EA;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  img{
    position: absolute;
    top: 8px;
    right: 12px;
  }
}
.section{
  margin-top: 20px;
  .course-classification{
    height: 38px;
    width: 100%;
    overflow-x: auto;
    display: flex;
    scrollbar-color:#40a0ff49 transparent;
    scrollbar-width:thin;
    &:hover{
      &::-webkit-scrollbar-thumb{
        background: #40a0ff49;
        border-radius: 10px;
      }
    }
    &::-webkit-scrollbar{
      width: 0;
      height: 5px;
    }
    // 滚动条轨道
    &::-webkit-scrollbar-track{
      background: transparent;
      border-radius: 2px;
    }
    // 小滑块
    &::-webkit-scrollbar-thumb{
      background: transparent;
      border-radius: 10px;
    }
    .course-classification-item{
      flex-shrink: 0;
      padding: 0 20px;
      height: 32px;
      background: white;
      border-radius: 8px;
      color: #333333;
      line-height: 32px;
      margin-right: 20px;
      cursor: pointer;
    }
    .course-classification-item-select{
      flex-shrink: 0;
      height: 32px;
      background: #2871EA;
      border-radius: 8px;
      color: white;
      line-height: 32px;
      margin-right: 20px;
      cursor: pointer;
    }
    .course-classification-add{
      cursor: pointer;
      flex-shrink: 0;
      width: 42px;
      height: 32px;
      background: white;
      border-radius: 8px;
      color: #999999;
      line-height: 32px;
      font-size: 26px;
    }
  }
  .question-box{
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px;
    .question-search{
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: 1px solid #E8E8E8;
      /deep/ .el-tag{
        max-width: calc(100% - 50px)!important;
      }
    }
    .question-list{
      padding-top: 20px;
      .question-list-item{
        padding-bottom: 20px;
        cursor: pointer;
        .question-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .question-title{
            padding-left: 15px;
            text-align: left;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 22px;
            &:before{
              position: absolute;
              content: '';
              width: 4px;
              height: 14px;
              background: #2871EA;
              border-radius: 2px;
              top: 5px;
              left: 0;
            }
          }
        }
        .question-label{
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 0 !important;
          }
          &::-webkit-scrollbar {
            width: 0 !important;
            height: 0;
          }
          .question-label-item{
            margin-top: 10px;
            flex-shrink: 0;
            padding: 3px 16px;
            background-color: #F8F8F8;
            color: #999999;
            font-size: 12px;
            border-radius: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div v-loading="rankLoading" class="rank-list">
    <div class="rank-title">习题热榜</div>
    <div v-if="rankList.length">
      <div v-for="(item,index) in rankList" :key="item.id" class="rank-item" @click="toDetail(item)">
        <div class="index" :style="{'color': index===0?'#FF3666':index===1?'#FF7000':index===2?'#FFA90F':'','background': index>2?'unset':''}">{{ index+1 }}</div>
        <div class="topicContent"><p>{{ item.topicContentText }}</p></div>
        <div class="commentsNumber">
          <span>{{ item.commentsNumber }}</span>
          <img :src="index>2?rankFireCool:rankFire" alt="">
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无内容" :image="require('@/assets/images/empty.png')" />
  </div>
</template>

<script>
import { getQuestionBank } from '@/api/interviewQuestionBank/interviewQuestion'

export default {
  name: 'Rank',
  data() {
    return {
      rankFireCool: require('@/assets/images/interviewQuestionBank/rankFireCool.png'),
      rankFire: require('@/assets/images/interviewQuestionBank/rankFire.png'),
      rankLoading: false,
      rankList: []
    }
  },
  created() {
    this.getRankList()
  },
  methods: {
    getRankList() {
      this.rankLoading = true
      getQuestionBank().then(res => {
        if (res.code === 200) {
          this.rankList = res.rows
          this.rankLoading = false
        }
      })
    },
    toDetail(item) {
      const routeData = this.$router.resolve({
        name: 'interviewQuestionDetail',
        query: {
          questionId: item.id,
          courseClassification: item.courseClassification,
          testClassification: item.testClassification
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped lang="less">
.rank-list{
  padding: 20px;
  background: url("../../../assets/images/interviewQuestionBank/rankBackground.png") no-repeat white;
  border-radius: 10px;
  margin-bottom: 20px;
  .rank-title{
    font-size: 16px;
    color: #333333;
    text-align: left;
  }
  .rank-item{
    display: flex;
    margin-top: 20px;
    align-items: center;
    cursor: pointer;
    .index{
      flex-shrink: 0;
      width: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #DDDDDD;
      background: url("../../../assets/images/interviewQuestionBank/hot.png") no-repeat bottom;
    }
    .topicContent{
      flex-grow: 1;
      padding-right: 10px;
      text-align: left;
      p{
        font-size: 14px;
        color: #333333;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
    .commentsNumber{
      flex-shrink: 0;
      margin-left: auto;
      color: #DDDDDD;
      span, img{
        vertical-align: middle;
      }
      img{
        margin-left: 5px;
      }
    }
  }
}
</style>

<template>
  <div class="index">
    <main class="main">
      <Carousel ref="Carousel" />
      <div class="wrapper">
        <section class="section">
          <nav class="nav">
            <div v-for="item in test_questions_classification" :key="item.id" @click="selectTestClassification(item.value)">
              <span :class="{ routeSelect: testClassification===item.value }">
                {{ item.label }}
              </span>
            </div>
          </nav>
          <interviewQuestion class="interviewQuestion" :test-classification="testClassification" />
        </section>
        <aside class="aside">
          <div class="my-collection">
            <img src="../../assets/images/interviewQuestionBank/myCollection.png" alt="">
            <span style="cursor: pointer" @click="openMyCollection">我的收藏</span>
            <myCollection ref="myCollection" />
          </div>
          <rank />
        </aside>
      </div>
    </main>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '@/components/indexBottom'
import rank from '@/views/interviewQuestionBank/components/rank'
import myCollection from '@/views/interviewQuestionBank/components/myCollection'
import { carouselChartList } from '@/api/interviewQuestionBank/interviewQuestion'
import interviewQuestion from '@/views/interviewQuestionBank/views/interviewQuestion'
import { mapState } from 'vuex'
import Carousel from '@/components/Carousel'

export default {
  name: 'QuestionsIndex',
  components: { indexBottom, rank, myCollection, interviewQuestion, Carousel },
  data() {
    return {
      carouselLoading: false,
      testClassification: '0',
      carouselList: [{}]
    }
  },
  computed: {
    ...mapState({
      test_questions_classification: state => {
        return state.dict.dictTree.test_questions_classification['arr'].filter(item => {
          return item.status === '0'
        })
      }
    })
  },
  created() {
    this.getCarouselList()
  },
  methods: {
    getCarouselList() {
      this.carouselLoading = true
      carouselChartList({ pageSize: 10, pageNum: 1 }).then(res => {
        if (res.code === 200) {
          this.carouselList = res.rows
          this.carouselLoading = false
        }
      })
    },
    openMyCollection() {
      this.$refs.myCollection.show()
    },
    selectTestClassification(value) {
      this.testClassification = value
    },
    toCloudClassroom(item) {
      window.open(item.linkAddress, '_blank')
    }
  }
}
</script>

<style scoped lang="less">
.index{
  margin-top: 70px;
  .main{
    width: 1200px;
    margin: 0 auto 20px;
    padding-top: 20px;
    .wrapper{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .section{
        width: 907px;
        .nav{
          display: flex;
          border-radius: 10px;
          height: 60px;
          background-color: white;
          user-select: none;
          overflow-x: auto;
          padding: 0 10px;
          scrollbar-color:#40a0ff49 transparent;
          scrollbar-width:thin;
          &:hover{
            &::-webkit-scrollbar-thumb{
              background: #40a0ff49;
              border-radius: 10px;
            }
          }
          &::-webkit-scrollbar{
            width: 0;
            height: 5px;
          }
          // 滚动条轨道
          &::-webkit-scrollbar-track{
            background: transparent;
            border-radius: 2px;
          }
          // 小滑块
          &::-webkit-scrollbar-thumb{
            background: transparent;
            border-radius: 10px;
          }
          &>div{
            padding: 0 10px;
            flex-shrink: 0;
            line-height: 60px;
            margin-left: 5px;
            span{
              cursor: pointer;
              font-size: 16px;
            }
            .routeSelect{
              position: relative;
              &:before{
                position: absolute;
                width: 30px;
                height: 5px;
                content: '';
                top: 18px;
                background: linear-gradient(-24deg, #2871EA, rgba(40,113,234,0.2));
                border-radius: 3px 0 3px 0;
              }
            }
          }
        }
      }
      .aside{
        width: 273px;
        .my-collection{
          background: white;
          border-radius: 10px;
          height: 60px;
          margin-bottom: 20px;
          img, span{
            vertical-align: middle;
          }
          span{
            padding: 0 50px;
            font-size: 18px;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>

<template>
  <el-dialog
    class="dialog-box"
    :close-on-click-modal="false"
    title="我的收藏"
    :visible.sync="dialogVisible"
    width="65%"
  >
    <el-form ref="searchForm" class="search-form" inline label-width="60px" :model="searchData">
      <el-form-item label="书签" prop="wordType">
        <el-select v-model="searchData.bookmarkId" class="menu-search-select" clearable placeholder="请选择书签">
          <el-option
            v-for="item in bookmarkList||[]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="分类">
        <el-select v-model="searchData.courseClassification" class="menu-search-select" clearable placeholder="请选择类型">
          <el-option
            v-for="item in course_classification"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="知识点" prop="wordType">
        <el-select v-model="searchData.knowledgeTag" class="menu-search-select" multiple collapse-tags clearable placeholder="请选择知识点">
          <el-option
            v-for="item in knowledgeList||[]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="企业" prop="wordType">
        <el-select v-model="searchData.enterpriseLabel" class="menu-search-select" multiple collapse-tags clearable placeholder="请选择企业">
          <el-option
            v-for="item in enterpriseList||[]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="collectionList"
      border
      style="width: 100%"
      :header-cell-style="{background: '#E7EFFB',textAlign: 'center'}"
      :cell-style="{textAlign: 'center'}"
      v-loading="tableLoading"
    >
      <el-table-column
        prop="questionBankName"
        label="试题名称"
      >
        <template slot-scope="scope">
          <el-tooltip :content="scope.row.questionBankName" placement="top">
            <p class="tooltip">{{ scope.row.questionBankName }}</p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="bookmarkName"
        label="书签"
        width="200"
      />
      <el-table-column
        prop="courseClassification"
        label="分类"
        width="200"
      >
        <template slot-scope="scope">
          {{ course_classification_obj[scope.row.courseClassification] }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="150"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleDetail(scope.row)">查看</el-button>
          <el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      class="pagination"
      layout="prev, pager, next"
      :current-page.sync="pageNum"
      :total="total"
      @current-change="onLoad"
    />
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import {
  bookmarkList,
  collectDelete,
  labelByStuPerson
} from '@/api/interviewQuestionBank/interviewQuestion'
import { myCollectionList } from '@/api/interviewQuestionBank/interviewQuestion'

export default {
  name: 'MyCollection',
  data() {
    return {
      dialogVisible: false,
      collectionList: [],
      total: 100,
      pageNum: 0,
      searchData: {},
      tableLoading: false,
      // 多选框列表书签/知识点/企业
      bookmarkList: [],
      knowledgeList: [],
      enterpriseList: []
    }
  },
  computed: {
    ...mapState({
      course_classification: state => {
        return state.dict.dictTree.course_classification['arr'].filter(item => {
          return item.status === '0'
        })
      },
      course_classification_obj: state => state.dict.dictTree.course_classification['obj'],
      userInfo: state => state.user.userInfo
    })
  },
  watch: {
    searchData: {
      handler() {
        this.onRefresh()
      },
      deep: true
    }
  },
  methods: {
    async show() {
      this.dialogVisible = true
      this.searchData = {
        bookmarkId: null,
        courseClassification: null,
        knowledgeTag: [],
        enterpriseLabel: []
      }
      await this.getBooKMark()
      await this.getLabelCollection()
      this.onRefresh()
    },
    handleDetail(item) {
      if (item.questionBankDel) {
        this.$message.warning('该题目已被删除')
        return
      }
      const routeData = this.$router.resolve({
        name: 'interviewQuestionDetail',
        query: {
          questionId: item?.questionBankId,
          courseClassification: item.courseClassification,
          testClassification: item.testClassification
        }
      })
      window.open(routeData.href, '_blank')
    },
    onRefresh() {
      this.pageNum = 1
      this.onLoad()
    },
    onLoad() {
      const obj = {
        studentId: this.userInfo.id,
        ...this.searchData,
        knowledgeTag: this.searchData.knowledgeTag.join(','),
        enterpriseLabel: this.searchData.enterpriseLabel.join(','),
        pageNum: this.pageNum,
        pageSize: 10
      }
      this.tableLoading = true
      myCollectionList(obj).then(res => {
        if (res.code === 200) {
          this.collectionList = res.rows
          this.total = res.total
          this.tableLoading = false
        }
      })
    },
    async getLabelCollection() {
      labelByStuPerson({ stuId: this.userInfo.id }).then(res => {
        if (res.code === 200) {
          this.knowledgeList = res.data?.knowledgeTagList ? [...res.data.knowledgeTagList] : []
          this.enterpriseList = res.data?.enterpriseLabelList ? [...res.data.enterpriseLabelList] : []
        }
      })
    },
    async getBooKMark() {
      const obj = { studentId: this.userInfo.id }
      await bookmarkList(obj).then(res => {
        if (res.code === 200) {
          this.bookmarkList = res.rows
        }
      })
    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除此选项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        collectDelete(id).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.onLoad()
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/styles/diyDialog";
.dialog-box{
  /deep/ .el-dialog{
    margin-top: 2vh!important;
    margin-bottom: 0;
  }
  /deep/ .el-dialog__header{
    text-align: left;
  }
}
.search-form{
  text-align: left;
}
.tooltip{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.pagination{
  text-align: right;
  padding: 30px 0 20px;
}
.menu-search-select{
  /deep/ .el-tag{
    max-width: calc(100% - 50px)!important;
  }
}
</style>
